import {SplideSlide} from "@splidejs/react-splide";
import React from "react";
import "./preview.scss"
import {Link} from "gatsby";

export default function PreviewMainPage(props) {
    const {
        hash,
        closing,
        trademarkHeadline,
    } = props

    return (
        <SplideSlide className={'preview-main bg-gray-300'}>
            <div className={'lg:h-full py-4 px-6 lg:py-8 lg:px-12'}>
                {trademarkHeadline}
                <div className={'mt-10 lg:mt-0 lg:h-5/6 xl:h-4/5 lg:flex lg:flex-wrap content-center'}>
                    <div className={'w-full text-center'}>
                        <p className={'my-4 text-3xl lg:text-4xl'}>This report is a free summary only.</p>
                        <p className={'my-4 text-3xl lg:text-4xl'}>Get your fully personalized report from 19 USD</p>
                        <p className={'my-12'}>
                            <Link
                                className={'px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-900 hover:bg-gray-700 md:py-4 md:text-lg md:px-10 lg:text-xl xl:text-2xl'}
                                to={`/pricing/${hash}`}>Get my report</Link>
                        </p>
                        {closing !== true &&
                            <p className={'my-4 text-3xl lg:text-4xl'}>Scroll down to see what you can get</p>
                        }
                    </div>
                </div>
            </div>
        </SplideSlide>
    )
}

import React from "react"
import {Router} from "@reach/router"
import Layout from "../components/Layout"
import PrivateRoute from "../components/PrivateRoute"
import Report from "../components/Report";

const R = () => (
    <Layout>
        <Router>
            <PrivateRoute path="/r/:hash" component={Report}/>
        </Router>
    </Layout>
)

export default R

import {SplideSlide} from "@splidejs/react-splide";
import React from "react";
import PageHeadline from "../../HTML/pageHeadline";
import "./suggestionsNote.scss";

export default function SuggestionsNotePage(props) {
    const {
        title,
        text,
        trademarkHeadline,
    } = props

    return (
        <SplideSlide className={'suggestions-note'}>
            <div className={'h-full py-4 px-6 lg:py-8 lg:px-12'}>
                {trademarkHeadline}
                <div className={'w-full mb-10'}>
                    <PageHeadline text={title}/>
                </div>
                <div className={'w-full mb-10 lg:mb-0'}>
                    {text.map((item, i) => (
                        <p key={i} dangerouslySetInnerHTML={{__html: item}} />
                    ))}
                </div>
            </div>
        </SplideSlide>
    )
}

import {SplideSlide} from "@splidejs/react-splide";
import React from "react";
import PageHeadline from "../../HTML/pageHeadline";
import "./populationComparison.scss"
import {VictoryAxis, VictoryBar, VictoryChart, VictoryLabel} from "victory";
import LockScreen from "./lockScreen";

const chartColors = {
    fire: '#ff4300',
    air: '#c8e6fb',
    water: '#173e66',
    earth: '#1bb919',
}

function generateRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function PreviewPopulationComparisonPage(props) {
    const {
        locale,
        legend,
        hash,
        trademarkHeadline,
    } = props

    const l = [];
    legend.forEach((el) => {
        l.push(<li key={el.name}><span style={{backgroundColor:el.symbol.fill}}/>{el.name}</li>)
    })

    const data = [
        {x: 'air', y: generateRandomNumber(30, 80)},
        {x: 'earth', y: generateRandomNumber(10, 100)},
        {x: 'fire', y: generateRandomNumber(10, 60)},
        {x: 'water', y: generateRandomNumber(20, 90)},
    ]

    return (
        <SplideSlide className={'population-comparison'}>
            <div className={'h-full py-4 px-6 lg:py-8 lg:px-12 relative'}>
                <LockScreen hash={hash}/>
                {trademarkHeadline}
                <div className={'w-full mb-10 lg:mb-0'}>
                    <PageHeadline text={locale.report.populationComparison.headline}/>
                </div>
                <div className={'lg:h-4/5 lg:flex lg:flex-wrap lg:items-center relative'}>
                    <div className={'w-full'}>
                        <p className={'text-lg lg:text-base xl:text-lg font-light lg:font-extralight text-justify blur'} dangerouslySetInnerHTML={{
                            __html: locale.dummy.generic_1
                        }}/>
                    </div>
                    <div className={'lg:w-5/12 relative'}>
                        <h5 className={'text-center text-xl font-extralight'}>{locale.report.populationComparison.figure}</h5>
                        <div className={'absolute centralise example-label text-center'}>
                            <span>SAMPLE DATA</span>
                        </div>
                        <VictoryChart>
                            <VictoryBar
                                horizontal={true}
                                data={data}
                                alignment={'middle'}
                                barRatio={1.5}
                                domain={{
                                    y: [0, 100]
                                }}
                                style={{
                                    data: {
                                        fill: (datum) => {
                                            return chartColors[datum.datum.x]
                                        }
                                    }
                                }}
                                labelComponent={<VictoryLabel/>}
                                labels={({datum}) => datum.y}
                            />
                            <VictoryAxis
                                dependentAxis={true}
                                scale={{y: 'linear'}}
                                range={{y: [0, 100]}}
                                orientation={'bottom'}
                                offsetY={25}
                                style={{
                                    grid: {
                                        // stroke: 'grey'
                                    },
                                    ticks: {
                                        stroke: 'grey',
                                        size: -5
                                    }
                                }}
                            />
                            <VictoryAxis
                                orientation={'left'}
                                scale={{x: 'linear'}}
                                tickFormat={(t) => {
                                    return locale.elements[t]
                                }}
                                style={{
                                    tickLabels: {
                                        fontSize: '10px',
                                        fontFamily: 'Jost, sans-serif',
                                        textTransform: 'uppercase'
                                    }
                                }}
                            />
                        </VictoryChart>
                        <ul className={'chart-legend'}>{l}</ul>
                    </div>
                    <div className={'lg:w-1/12'}/>
                    <div className={'lg:w-6/12 text-lg font-extralight text-justify'}/>
                    <div className={'w-full'}>
                        <p className={'text-lg lg:text-base xl:text-lg font-light lg:font-extralight text-justify blur'} dangerouslySetInnerHTML={{
                            __html: locale.dummy.generic_2
                        }}/>
                    </div>
                </div>
            </div>
        </SplideSlide>
    )
}

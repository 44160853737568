import React from "react";
import localization from "../../lang";
import "./results.scss"
import {getResults} from "../../utils/api";
import {NotificationManager} from "../Notification";
import Loader from "../Loader";
import {Splide} from "@splidejs/react-splide";
import ScrollExtension from "../SplideExtension/Scroll";
import NavigationExtension from "../SplideExtension/Navigation";
import IntroPage from "./results/intro";
import AboutPage from "./results/about";
import ElementsPage from "./results/elements";
import HowToNavigatePage from "./results/howToNavigate";
import PersonalityRatioPage from "./results/personalityRatio";
import PopulationComparisonPage from "./results/populationComparison";
import ElementFirePage from "./results/elementFire";
import ElementAirPage from "./results/elementAir";
import ElementEarthPage from "./results/elementEarth";
import ElementWaterPage from "./results/elementWater";
import FourElementsPage from "./results/fourElements";
import AreasPage from "./results/areas";
import AreaCommunicationPage from "./results/areaCommunication";
import AreaStyleOfWorkPage from "./results/areaStyleOfWork";
import AreaMotivationPage from "./results/areaMotivation";
import AreaStressManagementPage from "./results/areaStressManagement";
import FurtherDevelopmentPage from "./results/furtherDevelopment";
import SuggestionsPage from "./results/suggestions";
import SuggestionsIntroPage from "./results/suggestionsIntro";
import ReportTierEnum from "./ReportTierEnum";
import PreviewMainPage from "./preview/preview";
import SuggestionsNotePage from "./results/suggestionsNote";
import PreviewPopulationComparisonPage from "./preview/populationComparison";
import TrademarkHeadline from "../TrademarkHeadline";

class Results extends React.Component {
    state = {
        isLoading: false,
        respondent: {
            name: undefined,
            gender: 'undisclosed'
        },
        results: {
            pattern: null,
            values: {
                air: 0.0,
                water: 0.0,
                fire: 0.0,
                earth: 0.0
            },
            elements: [],
            texts: {
                intro_text: null,
                intro_text2: {
                    part1: null,
                    part2: null,
                    part3: null,
                },
                intro_headline2: null,
                results_headline: null,
                summary: null,
                shift: null,
                details: {
                    headline_1: null,
                    text_1: null,
                    strengths_1: null,
                    headline_2: null,
                    text_2: null,
                    strengths_2: null,
                    headline_3: null,
                    text_3: null,
                    strengths_3: null,
                    headline_4: null,
                    text_4: null,
                    strengths_4: null,
                    areas_to_develop_1: null,
                    areas_to_develop_text_1: null,
                    areas_to_develop_2: null,
                    areas_to_develop_text_2: null,
                    areas_to_develop_3: null,
                    areas_to_develop_text_3: null,
                    areas_to_develop_4: null,
                    areas_to_develop_text_4: null,
                },
                development: {
                    headline_1: null,
                    headline_2: null,
                    headline_3: null,
                    headline_4: null,
                    text_1: null,
                    text_2: null,
                    text_3: null,
                    elements: [],
                    suggestions: null,
                },
            }
        },
        isLoadingResults: true,
        barChartData: [],
        pieChartData: [],
        chartColorsData: [],
        chartLegendData: [],
        chartColors: {
            fire: '#ff4300',
            air: '#c8e6fb',
            water: '#173e66',
            earth: '#1bb919'
        },
        tier: ReportTierEnum.TIER_SUMMARY,
    }

    componentDidMount() {
        this.setState({
            tier: this.props.tier
        })
        this.fetchResults(this.props.hash)
    }

    _parseStrengths = (text) => {
        if (text === undefined) {
            return {
                text: null,
                str: null,
            }
        }

        const regex = /<div class="strengths">(.*?)<\/div>/s
        const matches = text.match(regex)

        if (matches !== null) {
            return {
                text: text.replace(matches[0], ''),
                str: matches[0].replace('Strengths', '<span>Your</span> strengths'),
            }
        }

        return {
            text: text,
            str: '',
        }
    }

    fetchResults = (hash) => {
        // Fetch the results
        getResults(hash, {}, () => {
            this.setState({
                isLoading: true
            })
        }).then((response) => {
            const {data} = response.data
            const bar = []
            const pie = []
            const colors = []
            const legend = []
            const language = this.props.language

            for (const el of data.data.information) {
                if (el.element === 'air') {
                    bar[3] = {
                        x: el.element,
                        y: Math.round(el.value)
                    }
                }

                if (el.element === 'fire') {
                    bar[2] = {
                        x: el.element,
                        y: Math.round(el.value)
                    }
                }

                if (el.element === 'earth') {
                    bar[1] = {
                        x: el.element,
                        y: Math.round(el.value)
                    }
                }

                if (el.element === 'water') {
                    bar[0] = {
                        x: el.element,
                        y: Math.round(el.value)
                    }
                }

                pie.push({
                    x: Math.round(el.percentage) + ' %',
                    y: Math.round(el.percentage)
                })
                colors.push(this.state.chartColors[el.element])
                legend.push({
                    name: localization[language].elements[el.element],
                    symbol: {fill: this.state.chartColors[el.element]}
                })
            }

            // Strengths
            const s1 = this._parseStrengths(data.texts.details.text_1)
            data.texts.details.strengths_1 = s1.str
            data.texts.details.text_1 = s1.text
            const s2 = this._parseStrengths(data.texts.details.text_2)
            data.texts.details.strengths_2 = s2.str
            data.texts.details.text_2 = s2.text
            const s3 = this._parseStrengths(data.texts.details.text_3)
            data.texts.details.strengths_3 = s3.str
            data.texts.details.text_3 = s3.text
            const s4 = this._parseStrengths(data.texts.details.text_4)
            data.texts.details.strengths_4 = s4.str
            data.texts.details.text_4 = s4.text
            // Weaknesses
            if (data.texts.details.areas_to_develop_1 !== undefined) {
                const w1 = this._parseStrengths(data.texts.details.areas_to_develop_1)
                data.texts.details.areas_to_develop_1 = w1.str
                data.texts.details.areas_to_develop_text_1 = w1.text
            }
            if (data.texts.details.areas_to_develop_2 !== undefined) {
                const w2 = this._parseStrengths(data.texts.details.areas_to_develop_2)
                data.texts.details.areas_to_develop_2 = w2.str
                data.texts.details.areas_to_develop_text_2 = w2.text
            }
            if (data.texts.details.areas_to_develop_3 !== undefined) {
                const w3 = this._parseStrengths(data.texts.details.areas_to_develop_3)
                data.texts.details.areas_to_develop_3 = w3.str
                data.texts.details.areas_to_develop_text_3 = w3.text
            }
            if (data.texts.details.areas_to_develop_4 !== undefined) {
                const w4 = this._parseStrengths(data.texts.details.areas_to_develop_4)
                data.texts.details.areas_to_develop_4 = w4.str
                data.texts.details.areas_to_develop_text_4 = w4.text
            }


            const summary = data.texts.summary.split("</p>\n<p>")
            data.texts.summary = summary[0];

            if (summary.length > 1) {
                data.texts.shift = summary[1]
            }

            this.setState({
                respondent: {
                    name: data.name,
                    gender: data.gender,
                },
                results: {
                    pattern: data.data.pattern,
                    texts: data.texts,
                    values: data.data.values,
                    elements: data.data.information
                },
                barChartData: bar,
                pieChartData: pie,
                chartColorsData: colors,
                chartLegendData: legend,
            })
        }).catch((e) => {
            if (e.response === undefined) {
                console.log(e)
            } else {
                const {response} = e
                const {errors} = response.data

                for (const err of errors) {
                    NotificationManager.error(err.title, 3000)
                }
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    sortElementPages = (tier, elements, locale, trademarkHeadline) => {
        const list = []

        for (const e of elements) {
            switch (e.element) {
                case 'air':
                    list.push(<ElementAirPage key={e.element} headline={locale.air.headline} text={locale.air.text} tier={tier} trademarkHeadline={trademarkHeadline}/>)
                    break
                case 'earth':
                    list.push(<ElementEarthPage key={e.element} headline={locale.earth.headline}
                                                text={locale.earth.text} tier={tier} trademarkHeadline={trademarkHeadline}/>)
                    break
                case 'fire':
                    list.push(<ElementFirePage key={e.element} headline={locale.fire.headline}
                                               text={locale.fire.text} tier={tier} trademarkHeadline={trademarkHeadline}/>)
                    break
                case 'water':
                    list.push(<ElementWaterPage key={e.element} headline={locale.water.headline}
                                                text={locale.water.text} tier={tier} trademarkHeadline={trademarkHeadline}/>)
                    break
                default:
            }
        }

        return list
    }

    prepareSuggestionsPages = (suggestions, locale, locked = false, hash = null, trademarkHeadline = null) => {
        const doc = new DOMParser().parseFromString(suggestions, 'text/html')
        const pages = [
            <SuggestionsIntroPage
                locked={locked}
                hash={hash}
                key={'00'}
                title={locale.headline}
                text={locale.intro} trademarkHeadline={trademarkHeadline}
            />
        ]
        const points = []

        let iterator = 0
        points[iterator] = []
        for (const el of doc.querySelectorAll('li')) {
            if (points[iterator].length === 4) {
                iterator++
            }

            if (points[iterator] === undefined) {
                points[iterator] = []
            }

            points[iterator].push(el.innerHTML)
        }

        let index = 0;
        let start = 1
        for(const p of points) {
            pages.push(<SuggestionsPage
                locked={locked}
                hash={hash}
                key={index}
                start={start}
                title={locale.headline}
                list={p} trademarkHeadline={trademarkHeadline}
            />)
            start += 4
            index++
        }

        return pages
    }

    prepareSuggestionsNotePages = (note, locale, trademarkHeadline) => {
        const doc = new DOMParser().parseFromString(note, 'text/html')
        const pages = [
            <SuggestionsIntroPage key={'00'} title={locale.headline} text={locale.intro} trademarkHeadline={trademarkHeadline}/>
        ]
        const points = []
        let iterator = 0
        points[iterator] = []
        for (const el of doc.querySelectorAll('p')) {
            if (points[iterator].length === 2) {
                iterator++
            }

            if (points[iterator] === undefined) {
                points[iterator] = []
            }

            points[iterator].push(el.innerHTML)
        }

        let index = 0;
        for(const p of points) {
            pages.push(<SuggestionsNotePage key={index} title={locale.headline} text={p} trademarkHeadline={trademarkHeadline}/>)
            index++
        }

        return pages
    }

    render() {
        const locale = localization[this.props.language]
        const {
            isLoading,
            respondent,
            results,
            pieChartData,
            barChartData,
            chartLegendData,
            chartColorsData,
            tier,
        } = this.state
        let elementPages;
        let fourElementsLocale;
        let suggestionsPages;
        const trademarkHeadline = <TrademarkHeadline name={respondent.name} date={new Date()}/>

        if (locale.report[tier] === undefined) {
            elementPages = this.sortElementPages(ReportTierEnum.TIER_SUMMARY, results.texts.development.elements, locale.report[ReportTierEnum.TIER_SUMMARY].elementPage, trademarkHeadline)
            fourElementsLocale = locale.report[ReportTierEnum.TIER_SUMMARY].fourElements
        } else {
            elementPages = this.sortElementPages(tier, results.texts.development.elements, locale.report[tier].elementPage, trademarkHeadline)
            fourElementsLocale = locale.report[tier].fourElements
        }

        if ([ReportTierEnum.TIER_SUMMARY].includes(tier)) {
            suggestionsPages = this.prepareSuggestionsPages(
                locale.dummy.suggestions,
                locale.report[tier].suggestions,
                true,
                this.props.hash,
                trademarkHeadline
            )
        }

        if ([ReportTierEnum.TIER_DEVELOPMENT].includes(tier) && results.texts.development.suggestions !== null) {
            suggestionsPages = this.prepareSuggestionsPages(
                results.texts.development.suggestions,
                locale.report[tier].suggestions,
                false,
                null,
                trademarkHeadline
            )
        }

        if ([ReportTierEnum.TIER_DEVELOPMENT].includes(tier) && results.texts.development.note !== false) {
            suggestionsPages = this.prepareSuggestionsNotePages(
                results.texts.development.note,
                locale.report[tier].suggestions,
                false,
                null,
                trademarkHeadline
            )
        }

        return (
            <div className={'w-full'}>
                <Loader active={isLoading} title={locale.app.loadingReport.title}
                        message={locale.app.loadingReport.message}/>
                <Splide
                    className={'h-full'}
                    options={{
                        pagination: false,
                        direction: 'ttb',
                        height: '100vh',
                        passive: true,
                        breakpoints: {
                            1023: {
                                destroy: true
                            }
                        }
                    }}
                    Extensions={{
                        ScrollExtension,
                        NavigationExtension
                    }}
                >
                    <IntroPage locale={locale} respondent={respondent} tier={tier}/>
                    <AboutPage locale={locale} respondent={respondent} texts={results.texts} trademarkHeadline={trademarkHeadline}/>
                    <ElementsPage locale={locale} trademarkHeadline={trademarkHeadline}/>
                    <HowToNavigatePage locale={locale} trademarkHeadline={trademarkHeadline}/>
                    <PersonalityRatioPage locale={locale} data={pieChartData} legend={chartLegendData}
                                          colors={chartColorsData} texts={results.texts} trademarkHeadline={trademarkHeadline}/>
                    {/* Population comparison graph */}
                    {[ReportTierEnum.TIER_DEVELOPMENT].includes(tier) &&
                    <PopulationComparisonPage locale={locale} data={barChartData} legend={chartLegendData}
                                              texts={results.texts} trademarkHeadline={trademarkHeadline}/>
                    }

                    {[ReportTierEnum.TIER_PERSONAL, ReportTierEnum.TIER_DEVELOPMENT].includes(tier) &&
                    <AreasPage locale={locale} trademarkHeadline={trademarkHeadline}/>
                    }

                    {[ReportTierEnum.TIER_PERSONAL, ReportTierEnum.TIER_DEVELOPMENT].includes(tier) &&
                    <AreaCommunicationPage locale={locale} text={results.texts.details.text_1} strengths={results.texts.details.strengths_1} trademarkHeadline={trademarkHeadline}/>
                    }

                    {[ReportTierEnum.TIER_DEVELOPMENT].includes(tier) &&
                        <AreaCommunicationPage
                            locale={locale}
                            text={results.texts.details.areas_to_develop_text_1}
                            strengths={results.texts.details.areas_to_develop_1}
                            trademarkHeadline={trademarkHeadline}
                            revert={true}
                        />
                    }

                    {[ReportTierEnum.TIER_PERSONAL, ReportTierEnum.TIER_DEVELOPMENT].includes(tier) &&
                    <AreaStyleOfWorkPage locale={locale} text={results.texts.details.text_2} strengths={results.texts.details.strengths_2} trademarkHeadline={trademarkHeadline}/>
                    }

                    {[ReportTierEnum.TIER_DEVELOPMENT].includes(tier) &&
                        <AreaStyleOfWorkPage
                            locale={locale}
                            text={results.texts.details.areas_to_develop_text_2}
                            strengths={results.texts.details.areas_to_develop_2}
                            trademarkHeadline={trademarkHeadline}
                            revert={true}
                        />
                    }

                    {[ReportTierEnum.TIER_PERSONAL, ReportTierEnum.TIER_DEVELOPMENT].includes(tier) &&
                    <AreaMotivationPage locale={locale} text={results.texts.details.text_3} strengths={results.texts.details.strengths_3} trademarkHeadline={trademarkHeadline}/>
                    }

                    {[ReportTierEnum.TIER_DEVELOPMENT].includes(tier) &&
                        <AreaMotivationPage
                            locale={locale}
                            text={results.texts.details.areas_to_develop_text_3}
                            strengths={results.texts.details.areas_to_develop_3}
                            trademarkHeadline={trademarkHeadline}
                            revert={true}
                        />
                    }

                    {[ReportTierEnum.TIER_PERSONAL, ReportTierEnum.TIER_DEVELOPMENT].includes(tier) &&
                    <AreaStressManagementPage locale={locale} text={results.texts.details.text_4} strengths={results.texts.details.strengths_4} trademarkHeadline={trademarkHeadline}/>
                    }

                    {[ReportTierEnum.TIER_DEVELOPMENT].includes(tier) &&
                        <AreaStressManagementPage
                            locale={locale}
                            text={results.texts.details.areas_to_develop_text_4}
                            strengths={results.texts.details.areas_to_develop_4}
                            trademarkHeadline={trademarkHeadline}
                            revert={true}
                        />
                    }

                    {[ReportTierEnum.TIER_PERSONAL, ReportTierEnum.TIER_DEVELOPMENT].includes(tier) &&
                    <FurtherDevelopmentPage locale={locale} trademarkHeadline={trademarkHeadline}/>
                    }

                    {[ReportTierEnum.TIER_DEVELOPMENT].includes(tier) &&
                        <>{suggestionsPages}</>
                    }

                    {elementPages}
                    <FourElementsPage
                        hash={this.props.hash}
                        tier={tier}
                        locale={fourElementsLocale} trademarkHeadline={trademarkHeadline}
                    />

                    {[ReportTierEnum.TIER_SUMMARY].includes(tier) &&
                        <>
                            <AreasPage
                                hash={this.props.hash}
                                locked={true}
                                locale={locale} trademarkHeadline={trademarkHeadline}
                            />
                            <AreaCommunicationPage
                                hash={this.props.hash}
                                locked={true}
                                locale={locale}
                                text={locale.dummy.details.text_1}
                                strengths={locale.dummy.details.strengths} trademarkHeadline={trademarkHeadline}
                            />
                            <AreaStyleOfWorkPage
                                hash={this.props.hash}
                                locked={true}
                                locale={locale}
                                text={locale.dummy.details.text_2}
                                strengths={locale.dummy.details.strengths} trademarkHeadline={trademarkHeadline}
                            />
                            <AreaMotivationPage
                                hash={this.props.hash}
                                locked={true}
                                locale={locale}
                                text={locale.dummy.details.text_3}
                                strengths={locale.dummy.details.strengths} trademarkHeadline={trademarkHeadline}
                            />
                            <AreaStressManagementPage
                                hash={this.props.hash}
                                locked={true}
                                locale={locale}
                                text={locale.dummy.details.text_4}
                                strengths={locale.dummy.details.strengths} trademarkHeadline={trademarkHeadline}
                            />
                            <PreviewPopulationComparisonPage
                                hash={this.props.hash}
                                locale={locale}
                                legend={chartLegendData} trademarkHeadline={trademarkHeadline}
                            />
                            {suggestionsPages}
                            <PreviewMainPage
                                closing={true}
                                hash={this.props.hash} trademarkHeadline={trademarkHeadline}
                            />
                        </>
                    }
                </Splide>
            </div>
        )
    }
}

export default Results
